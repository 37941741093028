import React, { useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import Product from "../components/product/Product";
import useAdmin from "../hooks/useAdmin";
import { Button } from "@mui/material";

const Shop = () => {
  let products = useLoaderData();
  const [productsState, setProductsState] = useState(products);

  const isAdmin = useAdmin();

  const removeProduct = (_id) => {
    setProductsState(products.filter((p) => p._id !== _id));
  };

  return (
    <div className="container">
      <h1>Shop page</h1>

      {isAdmin && (
        <Button color="secondary" variant="outlined">
          <Link to="/product/create">Add product</Link>
        </Button>
      )}

      <div className="products">
        {productsState.map((product) => (
          <Product
            product={product}
            key={product._id}
            removeProduct={removeProduct}
          />
        ))}
      </div>
    </div>
  );
};

export default Shop;
