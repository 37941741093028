import React, { useState } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import Product from "../components/product/Product";
import RangeSlider from "../components/RangeSlider/RangeSlider";

const Category = () => {
  const { name } = useParams();
    const products = useLoaderData();
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(9999999);

  return (
    <div className="container">
      <h1>{name}</h1>

      <div className="row-category">
        <div className="filter">
          <h2>Filter</h2>
          <RangeSlider products={products} setMax={setMax} setMin={setMin} />
        </div>

        <div className="products">
          {products.filter((p)=> p.price >= min && p.price <= max).map((product) => (
            <Product product={product} key={product._id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Category;
