import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Contacts from './pages/Contacts';
import Shop from './pages/Shop';
import { getCategories, getProduct, getProducts, getProductsFromCategory } from './loaders/products';
import ProductPage from './pages/ProductPage';
import ThemeProvider from './providers/ThemeProvider';
import WishlistProvider from './providers/WishlistProvider';
import Wishlist from './pages/Wishlist';
import Category from './pages/Category';
import AuthProvider from './providers/AuthProvider';
import AddProduct from './pages/admin/addProduct';
import EditProduct from './pages/admin/editProduct';


const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    loader: getCategories,
    errorElement: <h1>Page not Found</h1>,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: '/contacts',
        element: <Contacts />
      },
      {
        path: '/shop',
        element: <Shop />,
        loader: getProducts
      },
      {
        path: '/product/:id',
        element: <ProductPage />,
        loader: getProduct
      },
      {
        path: 'wishlist',
        element: <Wishlist />
      },
      {
        path: '/category/:name',
        element: <Category />,
        loader: getProductsFromCategory
      },
      {
        path: '/product/create',
        element: <AddProduct />
      },
      {
        path: '/product/edit/:id',
        element: <EditProduct />,
        loader: getProduct
      }
    ]
  },

]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <WishlistProvider>
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    </WishlistProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
