import { Button, TextField } from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { api } from "../../api/api";

const Login = ({ handleClose }) => {
  const { setAuthUser } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setEmailError(null);
    setPasswordError(null);

    try {
      const user = await api.post("/login", {
        email,
        password,
      });
      setAuthUser(user.data);
      setEmail("");
      setPassword("");
      handleClose();
    } catch (error) {
      const { errorFiled, message } = error.response.data;
      errorFiled === "email"
        ? setEmailError(message)
        : setPasswordError(message);
    }
  };

  return (
    <>
      <h2>Login Form</h2>
      <form onSubmit={handleSubmit}>
        <TextField
          type="email"
          variant="outlined"
          color="secondary"
          label="Email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          fullWidth
          required
          sx={{ mb: 4 }}
          error={emailError ? true : false}
          helperText={emailError}
        />

        <TextField
          type="password"
          variant="outlined"
          color="secondary"
          label="Password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          required
          fullWidth
          sx={{ mb: 4 }}
          error={passwordError ? true : false}
          helperText={passwordError}
        />

        <Button variant="outlined" color="secondary" type="submit">
          Login
        </Button>
      </form>
      {/* <small>
           Already have an account? <Link to="/login">Login Here</Link>
         </small> */}
    </>
  );
};

export default Login;
