import { Breadcrumbs, Button, Link, Rating, Typography } from "@mui/material";
import React from "react";
import { useLoaderData, useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Order from "../components/order/Order";

const ProductPage = () => {
  const product = useLoaderData();
  const isAuth = useAuth();

  return (
    <div className="container">
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link underline="hover" color="inherit" href="/shop">
          Shop
        </Link>
        <Typography color="text.primary">{product.title}</Typography>
      </Breadcrumbs>

      <div className="product-top">
        <div>
          <img src={product.image} alt={product.title} />
        </div>

        <div>
          <h1>Product {product.title}</h1>
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Rating
              readOnly
              defaultValue={product.rating.rate}
              precision={0.1}
            />
            <span>{product.rating.count} ratings</span>
          </div>

          <Order product={product} />
        </div>
      </div>

      <div>
        <h2>Description</h2>
        {product.description}
      </div>

      {isAuth && (
        <div>
          <h2>Set rating</h2>
          <Rating
            name="simple-controlled"
            value={0}
            onChange={(event, newValue) => {
              //setValue(newValue);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProductPage;
