import React, { useContext, useState } from "react";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { ThemeContext } from "../../contexts/ThemeContext";

const ThemeIcon = () => {
  const {theme, toggleTheme} = useContext(ThemeContext);
  const [active, setActive] = useState(false);

  const toggleActive = () => {
    setActive(!active);
    toggleTheme();
  };

  return (
    <div onClick={toggleActive} className="theme-icon">
      {active ? <DarkModeIcon /> : <LightModeIcon />}
    </div>
  );
};

export default ThemeIcon;
