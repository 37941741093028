import React, { useContext } from "react";
import "./Header.css";
import { Link, NavLink, useLoaderData } from "react-router-dom";
import ThemeIcon from "./Theme-icon";
import { ThemeContext } from "../../contexts/ThemeContext";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Badge } from "@mui/material";
import { WishlistContext } from "../../contexts/WishlistContext";
import Auth from "./Auth";
import { AuthContext } from "../../contexts/AuthContext";
import UserAuth from "./UserAuth";

const Header = () => {
  const categories = useLoaderData();
  const { authUser } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const { wishlistProducts } = useContext(WishlistContext);

  return (
    <header className={theme}>
      <div className="brand">
        <Link to="/">Company Name</Link>
      </div>

      <nav>
        <ul className="main-menu">
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/shop">Shop</NavLink>
            <ul className="sub-menu">
              {categories.map((category) => (
                <li key={category}>
                  <NavLink to={`/category/${category}`}>{category}</NavLink>
                </li>
              ))}
            </ul>
          </li>
          <li>
            <NavLink to="/contacts">Contacts</NavLink>
          </li>
        </ul>
      </nav>

      <div className="header-icons">
        <Link to="/wishlist">
          <Badge
            badgeContent={wishlistProducts.length}
            color="primary"
            showZero
          >
            <FavoriteBorderIcon />
          </Badge>
        </Link>

        <ThemeIcon />

        {authUser.token ? <UserAuth /> : <Auth />}
      </div>
    </header>
  );
};

export default Header;
