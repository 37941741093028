import { Box, Button, TextField, styled } from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { api, url } from "../../api/api";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddProduct = (event) => {
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");

  const navigate = useNavigate();
  const { authUser } = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = await api.post(
      "/products",
      {
        title,
        price,
        description,
        category,
        image,
        rating: {
          rate: 0,
          count: 0,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${authUser.token}`,
        },
      }
    );
    setTitle("");
    setPrice("");
    setDescription("");
    setCategory("");
    setImage("");
    navigate("/shop");
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    const response = await api.post(
      "/upload-image",
      formData
    );
    setImage(`${url}/uploads/${file.name}`);
  };
  return (
    <div className="container">

      <h1>Create Product</h1>
      <form onSubmit={handleSubmit}>
        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Title"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          fullWidth
          required
          sx={{ mb: 3 }}
        />
        <TextField
          type="number"
          variant="outlined"
          color="secondary"
          label="Price"
          onChange={(e) => setPrice(e.target.value)}
          value={price}
          fullWidth
          required
          sx={{ mb: 3 }}
        />

        <TextField
          multiline
          rows={4}
          variant="outlined"
          color="secondary"
          label="Description"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          fullWidth
          required
          sx={{ mb: 3 }}
        />

        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Category"
          onChange={(e) => setCategory(e.target.value)}
          value={category}
          fullWidth
          required
          sx={{ mb: 3 }}
        />

        {/* <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Image"
          onChange={(e) => setImage(e.target.value)}
          value={image}
          fullWidth
          required
          sx={{ mb: 3 }}
        /> */}

        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload file
          <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
        </Button>

        <Box style={{ marginTop: "2em" }}>
          <Button variant="outlined" color="secondary" type="submit">
            Add
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default AddProduct;
