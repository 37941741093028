import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import axios from "axios";
import ReactInputMask from "react-input-mask";

const tokenBot = "7391770405:AAG1hXhiHgb5jCr-2IdG808Bu3BolGbL8LI";
const chatId = "597037977";
const url = `https://api.telegram.org/bot${tokenBot}/sendMessage?chat_id=${chatId}`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Order({ product }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setShowTank(false);
    setName("");
    setPhone("");
  };

  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState(null);

  const [showThank, setShowTank] = React.useState(false);

  const phoneValidation = (phone) => {
    if (!/^\+38 \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(phone)) {
      setPhoneError("Phone invalid");
    } else {
      setPhoneError(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let message = `Name: ${name},%0APhone: ${phone},%0AProduct: ${product.title}`;
    axios.get(`${url}&text=${message}`);
    setShowTank(true);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        variant="contained"
        style={{ marginTop: "3em" }}
      >
        Make order
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Make order
          </Typography>
          {showThank ? (
            <h3>Thank!</h3>
          ) : (
            <form onSubmit={handleSubmit}>
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                fullWidth
                required
                sx={{ mb: 3, mt: 5 }}
              />

              <ReactInputMask
                mask="+38 (999) 999-99-99"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  phoneValidation(e.target.value);
                }}
                disabled={false}
                maskChar=" "
              >
                {() => (
                  <TextField
                    variant="outlined"
                    type="text"
                    color="secondary"
                    label="Phone"
                    fullWidth
                    required
                    helperText={phoneError}
                    error={phoneError ? true : false}
                    sx={{ mb: 3 }}
                  />
                )}
              </ReactInputMask>
              <Button variant="outlined" color="secondary" type="submit">
                Submit
              </Button>
            </form>
          )}
        </Box>
      </Modal>
    </div>
  );
}
