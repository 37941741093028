import React, { useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import axios from "axios";
import { api } from "../api/api";

const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState({
    user: null, // данные вошедшего пользователя
    token: null, // его токен
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && token !== "null") {
      getAuthUser(token);
    }
  }, []);

  const getAuthUser = async (token) => {
    const user = await api.get("/get-auth-user", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setAuthUser({ user: user.data, token });
  };

  useEffect(() => {
    localStorage.setItem("token", authUser.token);
  }, [authUser]);

  return (
    <AuthContext.Provider value={{ authUser, setAuthUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
