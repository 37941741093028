import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value) {
  return `${value}°C`;
}

export default function RangeSlider({ products, setMin, setMax }) {
  const prices = products.map((p) => p.price);
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);

  const [value, setValue] = React.useState([minPrice, maxPrice]);

  React.useEffect(() => {
    const prices = products.map((p) => p.price);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    setValue([minPrice, maxPrice]);
    setMin(minPrice);
    setMax(maxPrice);
  }, [products]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const filterProducts = (e, values) => {
    //console.log(values);
    setMin(values[0]);
    setMax(values[1]);
  };

  return (
    <Box sx={{ width: 300 }}>
      <Slider
        getAriaLabel={() => "Temperature range"}
        value={value}
        onChange={handleChange}
        onChangeCommitted={filterProducts}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        min={minPrice}
        max={maxPrice}
        style={{ width: "200px" }}
      />
    </Box>
  );
}
