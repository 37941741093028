import React, { useEffect } from "react";
import { useState } from "react";
import { WishlistContext } from "../contexts/WishlistContext";

const WishlistProvider = ({ children }) => {
  const [wishlistProducts, setWishlistProducts] = useState(JSON.parse(localStorage.getItem('wishlist')) || []);

    const toggleWishlistProduct = (product) => {
        const findProduct = wishlistProducts.find(p => p._id === product._id);
        if (findProduct) {
            // если товар уже есть в списке, то его удаляем
            setWishlistProducts(wishlistProducts.filter(p => p._id !== product._id));
        }
        else {
            // если товара нет в списке, то его добавляем
            setWishlistProducts([...wishlistProducts, product]);
        }
    };

    const inWishlist = (product) => { 
        const findProduct = wishlistProducts.find((p) => p._id === product._id);
        return findProduct ? true : false;
    }
    
    useEffect(() => {
        localStorage.setItem("wishlist", JSON.stringify(wishlistProducts));
    }, [wishlistProducts]);


  return (
    <WishlistContext.Provider
      value={{ wishlistProducts, toggleWishlistProduct, inWishlist }}
    >
      {children}
    </WishlistContext.Provider>
  );
};

export default WishlistProvider;
