import React, { useState } from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import Register from "./Register";
import Login from "./Login";

const Auth = () => {
  const [open, setOpen] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(true);

  const toggleShowRegisterForm = () => {
    setShowRegisterForm(!showRegisterForm);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleClickOpen}
        color="inherit"
      >
        <PersonOutlineIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ width: "50%", margin: "auto", "min-width": "300px" }}
      >
        <DialogTitle id="alert-dialog-title">{"Register or Login"}</DialogTitle>
        <DialogContent>
          {showRegisterForm ? (
            <Register toggleShowRegisterForm={toggleShowRegisterForm} />
          ) : (
            <Login handleClose={handleClose} />
          )}
        </DialogContent>
        <DialogActions>
          {showRegisterForm ? (
            <Button onClick={toggleShowRegisterForm}>Login</Button>
          ) : (
            <Button onClick={toggleShowRegisterForm}>Register</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Auth;
