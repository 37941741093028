import { Button, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { api } from "../../api/api";

const Register = ({ toggleShowRegisterForm }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const user = await api.post("/register", {
        name,
        email,
        password,
      });
      setName("");
      setEmail("");
      setPassword("");
      setError(null);
      toggleShowRegisterForm();
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  return (
    <>
      <h2>Register Form</h2>
      <form onSubmit={handleSubmit}>
        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Name"
          onChange={(e) => setName(e.target.value)}
          value={name}
          fullWidth
          required
          sx={{ mb: 4 }}
        />

        <TextField
          type="email"
          variant="outlined"
          color="secondary"
          label="Email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          fullWidth
          required
          sx={{ mb: 4 }}
          error={error}
          helperText={error}
        />

        <TextField
          type="password"
          variant="outlined"
          color="secondary"
          label="Password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          required
          fullWidth
          sx={{ mb: 4 }}
        />

        <Button variant="outlined" color="secondary" type="submit">
          Register
        </Button>
      </form>
      {/* <small>
           Already have an account? <Link to="/login">Login Here</Link>
         </small> */}
    </>
  );
};

export default Register;
