import React, { useContext } from 'react';
import Product from '../components/product/Product';
import { WishlistContext } from '../contexts/WishlistContext';

const Wishlist = () => {
    const { wishlistProducts } = useContext(WishlistContext);

    return (
      <div className="container">
        <h1>Wishlist</h1>
        <div className="products">
          {wishlistProducts.map((product) => (
            <Product product={product} key={product.id} />
          ))}
        </div>
      </div>
    );
}

export default Wishlist;
