import React, { useContext } from "react";
import "./Product.css";
import { Link } from "react-router-dom";
import { Button, Rating } from "@mui/material";
import WhishIcon from "./Whish-icon";
import useAdmin from "../../hooks/useAdmin";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import axios from "axios";
import { AuthContext } from "../../contexts/AuthContext";
import EditIcon from "@mui/icons-material/Edit";
import { api } from "../../api/api";

const Product = ({ product, removeProduct }) => {
  const isAdmin = useAdmin();
  const { authUser } = useContext(AuthContext);

  const clickDeleteHandler = async () => {
    // удаляем из БД
    await api.delete(`/products/${product._id}`, {
      headers: {
        Authorization: `Bearer ${authUser.token}`,
      },
    });

    removeProduct(product._id);
  };
  return (
    <div className="product">
      <Link to={`/product/${product._id}`}>
        <img src={product.image} alt={product.title} />
      </Link>

      <div className="title">
        <Link to={`/product/${product._id}`}>{product.title}</Link>
      </div>
      <div>
        <Rating
          readOnly
          defaultValue={product.rating.rate}
          precision={0.1}
          size="small"
        />

      </div>
      <div className="price">${product.price}</div>

      <WhishIcon product={product} />

      {isAdmin && (
        <div>
          <Button onClick={clickDeleteHandler}>
            <DeleteForeverIcon color="error" />
          </Button>

          <Button href={`/product/edit/${product._id}`}>
            <EditIcon color="info" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Product;
