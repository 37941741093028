import { Button, TextField } from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { api } from "../../api/api";

const EditProduct = (event) => {
  const product = useLoaderData();
  const [title, setTitle] = useState(product.title);
  const [price, setPrice] = useState(product.price);
  const [description, setDescription] = useState(product.description);
  const [category, setCategory] = useState(product.category);
  const [image, setImage] = useState(product.image);

  const navigate = useNavigate();
  const { authUser } = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await api.put(
      `/products/${product._id}`,
      {
        title,
        price,
        description,
        category,
        image
      },
      {
        headers: {
          Authorization: `Bearer ${authUser.token}`,
        },
      }
    );
    navigate("/shop");
  };

  return (
    <div className="container">
      <h1>Edit Product</h1>
      <form onSubmit={handleSubmit}>
        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Title"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          fullWidth
          required
          sx={{ mb: 3 }}
        />
        <TextField
          type="number"
          variant="outlined"
          color="secondary"
          label="Price"
          onChange={(e) => setPrice(e.target.value)}
          value={price}
          fullWidth
          required
          sx={{ mb: 3 }}
        />

        <TextField
          multiline
          rows={4}
          variant="outlined"
          color="secondary"
          label="Description"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          fullWidth
          required
          sx={{ mb: 3 }}
        />

        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Category"
          onChange={(e) => setCategory(e.target.value)}
          value={category}
          fullWidth
          required
          sx={{ mb: 3 }}
        />

        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Image"
          onChange={(e) => setImage(e.target.value)}
          value={image}
          fullWidth
          required
          sx={{ mb: 3 }}
        />

        <Button variant="outlined" color="secondary" type="submit">
          Save
        </Button>
      </form>
    </div>
  );
};

export default EditProduct;
