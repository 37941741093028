import React from 'react';

const Home = () => {
    return (
        <div>
            <h1>Home Page</h1>
            <p>Welcome to our website!</p>
            <p>Some content...</p>
            <p>Some more content...</p>
        </div>
    );
}

export default Home;
