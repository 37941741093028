import axios from "axios"
import { api } from "../api/api";

export const getProducts = async () => { 
    const response = await api.get('/products');
    return response.data;
}

export const getProduct = async ({params}) => {
    const response = await api.get(`/products/${params.id}`);
    const product = response.data;
    return product;
}

export const getCategories = async () => {
    const response = await api.get('/products/categories');
    return response.data;
}

export const getProductsFromCategory = async ({ params }) => {
    const response = await api.get(`/products/category/${params.name}`);
    return response.data;
}