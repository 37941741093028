import React, { useContext, useState } from 'react';
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { WishlistContext } from '../../contexts/WishlistContext';


const WhishIcon = ({product}) => {
    const { toggleWishlistProduct, inWishlist } = useContext(WishlistContext);
    const [active, setActive] = useState(inWishlist(product));

    const toggleActive = () => { 
        setActive(!active);
        toggleWishlistProduct(product);
    }

    return (
        <div onClick={toggleActive} className='whish-icon'>
            { active ? <FavoriteIcon /> : <FavoriteBorderIcon /> } 
        </div>
    );
}

export default WhishIcon;
